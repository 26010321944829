import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Responsive from "react-responsive";
import FadeIn from "react-fade-in";
import posed from "react-pose";
import GitHub from "./assets/github.svg";
import LinkedIn from "./assets/linkedin.svg";
import type from "./assets/type.png";
import ReactSVG from "react-svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

const Mobile = (props) => <Responsive {...props} maxWidth={767} />;
const Default = (props) => <Responsive {...props} minWidth={768} />;

const HoverHeader = posed.h2({
  idle: { scale: 1 },
  hovered: { scale: 1.05 },
});

const Hover = posed.div({
  idle: { scale: 1 },
  hovered: { scale: 1.1 },
});

const Fade = posed.div({});

class App extends Component {
  state = {
    hovering1: false,
    hovering2: false,
    hovering3: false,
    hovering4: false,
    hovering5: false,
  };
  render() {
    return (
      <main>
        <Helmet>
          <style>{"body { background-color: #ededed; }"}</style>
        </Helmet>

        <div className="gradient">
          <div className="container h-100">
            <div className="row justify-content-center align-items-center d-flex h-100">
              <div className="col-md-12">
                <h1>Sam Ware</h1>
                <h2 style={{ textAlign: "center" }}>@samwaree</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <Default>
            <FadeIn delay="200" transitionDuration="500">
              <hr />
              <div className="row" style={{ marginBottom: "50px" }}>
                <div
                  className="col"
                  style={{
                    marginLeft: "12%",
                    marginRight: "12%",
                  }}
                >
                  <HoverHeader
                    pose={this.state.hovering1 ? "hovered" : "idle"}
                    onMouseEnter={() => {
                      this.setState({ hovering1: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ hovering1: false });
                    }}
                  >
                    # about
                  </HoverHeader>
                  <p>
                    My name is Sam - I am a graduate of the University of
                    Wisconsin-Madison where I received a B.S. in Computer
                    Science and Applied Mathematics. I am currently pursuing a
                    career in Software Engineering. In my free time I like to
                    play the drums and take photos.
                  </p>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "50px" }}>
                <div
                  className="col"
                  style={{
                    marginLeft: "12%",
                    marginRight: "12%",
                  }}
                >
                  <HoverHeader
                    pose={this.state.hovering2 ? "hovered" : "idle"}
                    onMouseEnter={() => {
                      this.setState({ hovering2: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ hovering2: false });
                    }}
                  >
                    # projects
                  </HoverHeader>
                  <p>
                    <a href="https://type.samware.dev">type</a> - A simple,
                    sleek typing test to measure how fast you can type. Based on
                    typings.gg.
                  </p>
                  <p>
                    <a href="https://food.samware.dev">Food</a> - A website
                    designed to answer the age old question: where should we
                    eat? Shows you restuarants around the area represented as
                    Tinder-like cards for you and your friends to 'swipe' on, so
                    you can discover new restaurants and find one that everyone
                    wants to eat at.
                  </p>
                  {/* <img class="type" src={type} alt="type_image" /> */}
                  <p>
                    To see other projects that I am currently working on and
                    have worked on in the past, visit my{" "}
                    <a href="https://github.com/samwaree">github profile</a>.
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col"
                  style={{
                    marginLeft: "12%",
                    marginRight: "12%",
                  }}
                >
                  <HoverHeader
                    pose={this.state.hovering3 ? "hovered" : "idle"}
                    onMouseEnter={() => {
                      this.setState({ hovering3: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ hovering3: false });
                    }}
                  >
                    # contact
                  </HoverHeader>
                  <p>
                    To contact me, email{" "}
                    <a href="mailto:contact@samware.dev">contact@samware.dev</a>
                  </p>
                </div>
              </div>
              <hr />
              <div className="row" style={{ marginBottom: "50px" }}>
                <div
                  className="col-auto"
                  style={{ float: "none", margin: "auto" }}
                >
                  <div className="row">
                    <div className="col-auto">
                      <Hover
                        pose={this.state.hovering4 ? "hovered" : "idle"}
                        onMouseEnter={() => {
                          this.setState({
                            hovering4: true,
                          });
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            hovering4: false,
                          });
                        }}
                      >
                        <ReactSVG
                          src={GitHub}
                          svgStyle={{ width: 30 }}
                          onClick={() => {
                            window.location = "https://github.com/samwaree";
                          }}
                        />
                      </Hover>
                    </div>
                    <div className="col-auto">
                      <Hover
                        href="#"
                        pose={this.state.hovering5 ? "hovered" : "idle"}
                        onMouseEnter={() => {
                          this.setState({
                            hovering5: true,
                          });
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            hovering5: false,
                          });
                        }}
                        onClick={() => {
                          window.location =
                            "https://www.linkedin.com/in/samuelrware/";
                        }}
                      >
                        <ReactSVG src={LinkedIn} svgStyle={{ width: 30 }} />
                      </Hover>
                    </div>
                  </div>
                </div>
              </div>
            </FadeIn>
          </Default>
          <Mobile>
            <FadeIn delay="200" transitionDuration="500">
              <hr />
              <div className="row" style={{ marginBottom: "50px" }}>
                <div className="col">
                  <h2># about</h2>
                  <p>
                    My name is Sam - I am a graduate of the University of
                    Wisconsin-Madison where I received a B.S. in Computer
                    Science and Applied Mathematics. I am currently pursuing a
                    career in Software Engineering. In my free time I like to
                    play the drums and take photos.
                  </p>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "50px" }}>
                <div className="col">
                  <h2># projects</h2>
                  <p>
                    <a href="https://type.samware.dev">type</a> - A simple,
                    sleek typing test to measure how fast you can type. Based on
                    typings.gg.
                  </p>
                  <p>
                    <a href="https://food.samware.dev">Food</a> - A website
                    designed to answer the age old question: where should we
                    eat? Shows you restuarants around the area represented as
                    Tinder-like cards for you and your friends to 'swipe' on, so
                    you can discover new restaurants and find one that everyone
                    wants to eat at.
                  </p>
                  <p>
                    To see projects that I am currently working on and have
                    worked on in the past, visit my{" "}
                    <a href="https://github.com/samwaree">github profile</a>.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h2># contact</h2>
                  <p>
                    To contact me, email{" "}
                    <a href="mailto:contact@samware.dev">contact@samware.dev</a>
                  </p>
                </div>
              </div>
              <hr />
              <div className="row" style={{ marginBottom: "50px" }}>
                <div
                  className="col-auto"
                  style={{ float: "none", margin: "auto" }}
                >
                  <div className="row">
                    <div className="col-auto">
                      <Hover
                        pose={this.state.hovering4 ? "hovered" : "idle"}
                        onMouseEnter={() => {
                          this.setState({
                            hovering4: true,
                          });
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            hovering4: false,
                          });
                        }}
                      >
                        <ReactSVG
                          src={GitHub}
                          svgStyle={{ width: 30 }}
                          onClick={() => {
                            window.location = "https://github.com/samwaree";
                          }}
                        />
                      </Hover>
                    </div>
                    <div className="col-auto">
                      <Hover
                        href="#"
                        pose={this.state.hovering5 ? "hovered" : "idle"}
                        onMouseEnter={() => {
                          this.setState({
                            hovering5: true,
                          });
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            hovering5: false,
                          });
                        }}
                        onClick={() => {
                          window.location =
                            "https://www.linkedin.com/in/samuelrware/";
                        }}
                      >
                        <ReactSVG src={LinkedIn} svgStyle={{ width: 30 }} />
                      </Hover>
                    </div>
                  </div>
                </div>
              </div>
            </FadeIn>
          </Mobile>
        </div>
      </main>
    );
  }
}

export default App;
